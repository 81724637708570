





















































import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import FormCard from "@/components/form/FormCard.vue";
import ExpressionOfInterestFormFields from "@/components/common/ExpressionOfInterestFormFields.vue";
import BaseForm from "@/components/form/BaseForm";
import ExpressionOfInterestVersions from "@/components/project-buyers/expression-of-interest/ExpressionOfInterestVersions.vue";
import CurrencyInput from "@/components/form/input/CurrencyInput.vue";
import ProjectsService from "@/services/ProjectsService";
import EoiEdit from "./expression-of-interest/EoiEdit.vue";
import EoiPreview from "./expression-of-interest/EoiPreview.vue";
import {
  ExpressionOfInterestFormQuestion,
  ExpressionOfInterestVersion,
} from "@/entity/ExpressionOfInterest";

@Component({
  components: {
    FormCard,
    ExpressionOfInterestFormFields,
    ExpressionOfInterestVersions,
    CurrencyInput,
    EoiEdit,
    EoiPreview,
  },
})
export default class ProjectExpressionOfInterestFormCard extends Mixins(
  BaseForm
) {
  @Prop({ required: true, type: Number }) projectId!: number;

  versions: ExpressionOfInterestVersion[] = [];
  versionId = 1;
  originalQuestions: ExpressionOfInterestFormQuestion[] = [];
  editedQuestions: ExpressionOfInterestFormQuestion[] = [];

  tabItems = [
    { key: "Aktuelle Version", name: "Aktuelle Version" },
    { key: "Edit", name: "Edit" },
    { key: "EDIT Vorschau", name: "EDIT Vorschau" },
  ];
  activeTab = 0;

  isSaving = false;
  isLoading = true;
  isValid = false;

  async handleEoiEdit() {
    const updatedQuestions = await ProjectsService.editEoiVersion(
      this.projectId,
      this.versionId,
      this.editedQuestions.map((item) => ({
        primaryText: item.primaryText || "",
        secondaryText: item.secondaryText || "",
        questionTypeId: item.questionTypeId,
        orderNum: item.orderNum,
        answerOptions: item.answerOptions
          ? [
              item.answerOptions.answer1 || "",
              item.answerOptions.answer2 || "",
              item.answerOptions.answer3 || "",
              item.answerOptions.answer4 || "",
            ]
          : null,
      }))
    );
    this.originalQuestions = updatedQuestions.sort(
      (a, b) => a.orderNum - b.orderNum
    );
    this.editedQuestions = this.originalQuestions;
    this.activeTab = 0;
  }

  async addVersion() {
    const data = await ProjectsService.addEoiVersion(this.projectId);
    this.versions.push({ version: data, isLocked: false });
    this.versionId = data;
  }

  get isThereNotLockedVersion() {
    return this.versions.find((item) => !item.isLocked) !== undefined;
  }

  async fetchEoiVersion(): Promise<void> {
    const response = await ProjectsService.getEoiQuestionsAndAnswerOptions(
      this.projectId,
      this.versionId
    );
    this.originalQuestions = response.sort((a, b) => a.orderNum - b.orderNum);
    this.editedQuestions = this.originalQuestions;
  }

  async fetchEoiVersions(): Promise<void> {
    const versionsRes = await ProjectsService.getEoiVersions(this.projectId);
    this.versions = [...versionsRes];

    this.versionId = this.versions[this.versions.length - 1]?.version;
  }

  async handleTabClick(tab: number) {
    if (tab === 0) {
      this.isValid = false;
    } else {
      this.isValid = true;
    }
  }

  isVersionDisabled(versionId: number) {
    return this.versions.find((item) => item.version === versionId)?.isLocked;
  }

  async created(): Promise<void> {
    await this.fetchEoiVersions();
    this.isLoading = false;
  }

  @Watch("versionId", { immediate: true })
  onVersionChange() {
    if (this.versionId) {
      this.fetchEoiVersion();
    }
  }
}
